<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex align-space-between align-md-center justify-space-between flex-column flex-md-row mb-8"
    >
      <v-row class="mb-8 mb-md-0" no-gutters>
        <v-col cols="12" sm="10" md="8" lg="7" xl="6">
          <custom-input
            v-model="search"
            placeholder="Realizar busca"
            appendIcon="mdi-magnify"
            :required="false"
            :hideDetails="true"
            @keyup="searchPlayer()"
          />
        </v-col>
      </v-row>

      <v-spacer />

      <section class="d-flex justify-end">
        <switch-show-type @change="searchPlayer()" />
      </section>
    </div>

    <loader-content v-if="loading" />

    <card-grid
      v-else-if="$store.getters.viewType === 'grid'"
      :items="search ? filteredPlayers : _players"
      @submit="$router.push({ path: `/league/player-details/${$event}` })"
    />

    <!-- list mode -->
    <custom-table
      v-else
      :headers="headers"
      :items="search ? filteredPlayers : players"
    >
      <template v-slot:[`item.player`]="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar v-if="$vuetify.breakpoint.smAndUp" tile>
            <v-img
              :src="
                item.user.avatar || require('@/assets/league/empty-user.png')
              "
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <router-link
                class="overtext--text"
                :to="`/league/player-details/${item.user.id}`"
              >
                {{ item.user.nickname }}
              </router-link>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar v-if="$vuetify.breakpoint.xsOnly" tile>
            <v-img
              :src="
                item.user.avatar || require('@/assets/league/empty-user.png')
              "
            />
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <template v-slot:[`item.team`]="{ item }">
        <router-link
          class="overtext--text"
          :to="`/league/team-details/${item.team.id}`"
        >
          {{ item.team.name }}
        </router-link>
      </template>

      <template v-slot:[`item.schoolCertificate`]="{ item }">
        <a :href="item.user.schoolCertificate" target="_blank"> Visualizar </a>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="red" :color="getStatus(item).color" label>
          <span class="text--body-3 px-2" v-text="getStatus(item).name" />
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-1 rounded-lg"
          color="red"
          disabled
          icon
          small
          @click="rejectPlayer(item)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-btn
          class="rounded-lg"
          color="green"
          disabled
          icon
          small
          @click="approvePlayer(item)"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </template>
    </custom-table>
  </div>
</template>

<script>
import { formatDate } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      interval: null,
      search: "",
      headers: [
        {
          text: "Jogador",
          value: "player",
        },
        {
          text: "Equipe",
          value: "team",
        },
        {
          text: "Comprovante escolar",
          value: "schoolCertificate",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Ações",
          value: "actions",
        },
      ],
      filteredPlayers: [],
    };
  },

  props: {
    league: {
      type: null,
      required: true,
    },
  },

  computed: {
    players() {
      const players = [];

      this.league.teams.forEach((e) => {
        e.team_players.forEach((j) => {
          players.push({
            team: { id: e.id, name: e.name, logo: e.logo },
            ...j,
          });
        });
      });

      return players;
    },

    _players() {
      return this.players.map((e) => {
        return {
          id: e.user.id,
          image: e.user.avatar || require("@/assets/league/empty-user.png"),
          title: e.user.nickname,
          subtitle: e.team.name,
        };
      });
    },
  },

  methods: {
    rejectPlayer(item) {},

    approvePlayer(item) {},

    searchPlayer() {
      if (this.interval) clearInterval(this.interval);

      if (this.search) {
        this.loading = true;

        this.interval = setTimeout(() => {
          if (this.$store.getters.viewType === "grid") {
            this.filteredPlayers = this._players.filter((e) => {
              return e.title.toLowerCase().includes(this.search.toLowerCase());
            });
          } else {
            this.filteredPlayers = this.players.filter((e) => {
              return e.user.nickname
                .toLowerCase()
                .includes(this.search.toLowerCase());
            });
          }

          this.loading = false;
        }, 300);
      } else {
        this.filteredPlayers = [];
        this.loading = false;
      }
    },

    getStatus(item) {
      return { name: "...", color: "grey" };
    },

    formatDate,
  },
};
</script>

<style></style>
